import React from 'react'
import { css } from 'emotion'
import { Link, graphql } from 'gatsby'

import Layout from '../../../components/Layout'
import Title from '../../../components/Title'
import CatalogMembers from '../../../components/ref/CatalogMembers';

const main = css({
  label: 'empresarias',
  '.find-box .button': {
    color: '#fff !important'
  },

  '.content': {
    padding: '2em 0'
  },

  '.find-box': {
    justifyContent: 'center',
  },

  'select': {
    width: '100%'
  }
})

export default class MembersPage extends React.Component {

  constructor(props) {
    super(props);

    const { allMembers, allServices, allProducts, allCountries } = props.data;

    const countries = allCountries.edges.map(node => node.node);
    countries.push({ pais: 'Todos', codigo: '000', provinces: [] });
    const members = allMembers.edges.map(member => member.node);

    this.state = {
      allMembers: members,
      allProducts: allProducts.edges[0].node.name.sort(),
      allServices: allServices.edges[0].node.name.sort(),
      allCountries: countries,
      search: '',
      location: {
        province: '',
        country: 'Todos',
        code: '000'
      },
      selectedProduct: 'Todos',
      selectedService: 'Todos',
      keyword: '',
      currentPage: 1,
      itemsPerPage: 6
    }
  }

  handleSearchButton = (e) => {
    e.preventDefault();
    this.filterMembers();
  }

  filterMembers = () => {
    const { allMembers, search = '', selectedProduct, selectedService, location } = this.state;
    let results = allMembers;

    if (location.code !== '000') {
      results = allMembers.filter(member => member.location.codigo === location.code);
    }

    if (location.province !== '') {
      results = results.filter(member => member.location.province === location.province);
    }

    if (selectedProduct !== 'Todos') {
      results = results.filter(member => member.selectedProducts.includes(selectedProduct));
    }

    if (selectedService !== 'Todos') {
      results = results.filter(member => member.selectedServices.includes(selectedService));
    }

    if (search) {
      results = results.filter(member => member.business_name.toLowerCase().includes(search.toLowerCase())
        || member.description.toLowerCase().includes(search.toLocaleLowerCase()))
    }

    this.setState({ selectedMembers: results });
  }

  handleSearch = (e) => {
    this.setState({ search: e.target.value });
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 13) // enter key
      this.filterMembers();
  }

  handleCategoryChange = (e) => {
    let { selectedProduct, selectedService } = this.state;

    if (e.currentTarget.name === 'product') {
      selectedProduct = e.currentTarget.value;
    } else {
      selectedService = e.currentTarget.value;
    }

    this.setState({ selectedProduct, selectedService });
  }

  handleLocationChange = (e) => {
    const { location } = this.state;

    location[e.currentTarget.name] = e.currentTarget.value;

    this.setState({ location });
  }

  render() {
    const { allProducts, allServices, selectedMembers, allCountries, location } = this.state;

    const renderTitle = () => {
      return !selectedMembers ? <h3 className="has-text-centered">Utilice la búsqueda para encontrar empresarias</h3> : selectedMembers.length ?
        <h3 className="has-text-centered">Empresarias encontradas</h3> : <h3 className="has-text-centered">No se han encontrado empresarias</h3>;
    }

    return (
      <Layout>
        <section className={main}>
          <div className="content">
            <div className="columns">
              <div className="column is-10 is-offset-1">

                <Title title="Buscar Empresarias" />
                <br />

                <div className="box">
                  <div className="columns">
                    <div className="column">
                      <div className="field">
                        <label className="label has-text-centered">Nombre/Descripción</label>
                        <div className="control">
                          <input
                            className="input"
                            type="text"
                            name="search"
                            onChange={this.handleSearch}
                            value={this.state.search}
                            onKeyDown={this.handleKeyPress}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="column">
                      <div className="field">
                        <label className="label has-text-centered">Productos</label>
                        <div className="field">
                          <div className="control">
                            <div className="select is-primary is-flex">
                              <select name="product" value={this.state.selectedProduct} onChange={this.handleCategoryChange}>
                                <option defaultValue="Todos">Todos</option>
                                {
                                  allProducts.length && allProducts.map(product => <option value={product} key={product}>{product}</option>)
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="column">
                      <div className="field">
                        <label className="label has-text-centered">Servicios</label>
                        <div className="field">
                          <div className="control">
                            <div className="select is-primary is-flex">
                              <select name="service" value={this.state.selectedService} onChange={this.handleCategoryChange}>
                                <option defaultValue="seleccionar">Todos</option>
                                {
                                  allServices.length && allServices.map(service => <option value={service} key={service}>{service}</option>)
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="columns">
                    <div className="column">
                      <div className="field">
                        <label className="label has-text-centered">País</label>
                        <div className="field">
                          <div className="control">
                            <div className="select is-primary is-flex">
                              <select name="code" value={location.code} onChange={this.handleLocationChange}>
                                {
                                  allCountries.length && allCountries.sort().map(country => <option value={country.codigo} key={country.codigo}>{country.pais}</option>)
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="column">
                      <div className="field">
                        <label className="label has-text-centered">Provincia/Departamento</label>
                        <div className="field">
                          <div className="control">
                            <div className="select is-primary is-flex">
                              <select name="province" value={location.province} onChange={this.handleLocationChange}>
                                <option defaultValue="seleccionar">Todas</option>
                                {
                                  allCountries.length && (allCountries.find(country => country.codigo === location.code)).provinces.sort()
                                    .map(province => <option value={province} key={province}>{province}</option>)
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="find-box is-flex">
                    <button className="button is-primary" onClick={(e) => this.handleSearchButton(e)}>Buscar</button>
                  </div>

                </div>

                {renderTitle()}
                <CatalogMembers selectedMembers={this.state.selectedMembers} selectedService={this.state.selectedService}/>

                <div className="column has-text-centered">
                  <Link className="button has-text-primary" to={'/red-economica-feminista'} rel="next">
                    {'< Regresar'}
                  </Link>
                </div>

              </div>

            </div>
          </div>
        </section>
      </Layout>

    )
  }
}


export const pageQuery = graphql`
{
  allMembers(filter: { enabled: { eq: true } }) {
    edges {
      node {
        id
        name
        business_name
        business_name_lc
        enabled
        about_fem
        about_support
        categories
        selectedServices
        selectedProducts
        logo
        description
        location {
          province
          codigo
          pais
        }
      } 
    }
  }
  allServices {
    edges {
      node {
        name
      }
    }
  }
  allProducts {
    edges {
      node {
        name
      }
    }
  }
  allCountries {
    edges {
      node {
        id
        pais
        codigo
        provinces
      }
    }
  }    

}
`