import React, { useState, useEffect } from "react"
import MemberCard from "../MemberCard"

const CatalogMembers = ({ selectedMembers = [], selectedService }) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);

  const last = currentPage * itemsPerPage;
  const first = last - itemsPerPage;
  const currentItems = selectedMembers.slice(first, last);
  const totalPages = Math.ceil(selectedMembers.length / itemsPerPage);

  let half = Math.floor(currentItems.length / 2);
  let smLeft = currentItems.slice(0, half);
  let smRight = currentItems.slice(half, currentItems.length);

  const handlePageChange = (i) => setCurrentPage(currentPage + i);

  useEffect(() => {
    if ((currentItems.length < itemsPerPage) && (currentPage > totalPages))
      setCurrentPage(1);
  });

  return (
    <div>
      <hr />
      <div className="members columns">
        <div className="column">
          {
            smRight &&
            smRight.map((member, i) =>
              <MemberCard member={member} key={i} isService={!!selectedService}/>
            )
          }
        </div>
        <div className="column">
          {
            smLeft &&
            smLeft.map((member, i) =>
              <MemberCard member={member} key={i} isService={!!selectedService}/>
            )
          }
        </div>
      </div>

      <div>
        {
          totalPages > 0 && <>
            <hr />
            <nav className="pagination is-centered" role="navigation" aria-label="pagination">
              <a className="pagination-previous" onClick={() => handlePageChange(-1)} disabled={currentPage <= 1}>Anterior</a>
              <a className="pagination-next" onClick={() => handlePageChange(1)} disabled={currentPage === totalPages}>Siguiente</a>
            </nav>
            <div className="has-text-centered">
              {
                <span className="has-text-weight-semibold	">Página {currentPage} de {totalPages}</span>
              }
            </div>
          </>
        }
      </div>
      <br />
    </div>
  )
}

export default CatalogMembers;