import React from 'react'
import { Link } from 'gatsby'
import { css } from 'emotion'

const main = css({
  label: 'member-card',
  width: '100% !important',

  p: {
    display: 'inline',
    textOverflow: 'ellipsis'
  },

  figure: {
    display: 'flex'
  },

  h2: {
    color: '#000',
    padding: '0.2em 0 !important',
    margin: '0 !important',
    fontSize: '1.7rem !important',    
    textDecorationSkipInk: 'none',
    textUnderlinePosition: 'under',
    '&.product': {
      textDecoration: 'underline solid #6039B5',
    },
    '&.service': {
      textDecoration: 'underline solid #73CE57',
    }
  },

  span: {
    fontWeight: 'bold'
  },

  '.card-image figure': {
    margin: '0 auto !important'
  },

  '.card-image': {
    padding: '0.3rem 0',
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
  },

  '.view-profile-btn': {
    display: 'flex',
    justifyContent: 'flex-end'
  },

  '.card': {
    height: '90%'
  },

  '.button': {
    color: '#fff'
  },

  '@media all and (max-width: 450px)': {
    '.card-content, .card-image': {
      padding: '0.1em'
    }
  }
})

const truncate = (str, maxLength = 300) => {
  if (str.length <= maxLength) return str;

  const trimmed = str.substr(0, maxLength);
  return `${trimmed.substr(0, Math.min(trimmed.length, trimmed.lastIndexOf(" ")))}...`;
}

const MemberCard = ({ member, maxLength = 240, smallLogo = false, isService = false }) => (
  <div className={main}>
    <div className="card">
      <div className="card-image">
        <figure className={`image ${!smallLogo ? 'is-128x128' : 'is-64x64'}`}>
          <img className="is-rounded" src={member.logo || 'https://res.cloudinary.com/chicasalfrente/image/upload/v1544307277/logo-default.png'} alt="Placeholder" onError={(ev) => ev.target.src = 'https://res.cloudinary.com/chicasalfrente/image/upload/v1544307277/logo-default.png' } />
        </figure>
        <Link to={`/red-economica-feminista/empresarias/${member.business_name_lc}`}>
          <h2 className={`title is-3 has-text-centered ${isService ? 'service' : 'product'}`}>{member.business_name}</h2>
        </Link>
      </div>
      <div className="card-content">
        <div className="content">
          <span>Encargada: </span><p>{member.name}</p>
          <br />
          <span>Descripción: </span><p>{truncate(member.description, maxLength)}</p>

          <div className="view-profile-btn">
            <Link className="button is-small is-primary" style={{ color: '#fff' }} to={`/red-economica-feminista/empresarias/${member.business_name_lc}`}>Ver perfil</Link>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default MemberCard;